import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { PillLink } from "../../components/ui/pill"
import Tags from "../../components/tags"
import { keywords } from "../../util/helpers"
import { IndexHeader } from "../../components/header"
import { projectCategoryVariant } from "../../util/styles"
import Icon from "../../components/icon"
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Projects"
        description="Wide range of coding projects that I have started, done, or been part of."
        path="/projects"
        keywords={[
          "open source",
          "web applications",
          "fullstack web applications",
          "libraries",
          "programming",
          ...keywords(data.allMarkdownRemark.edges, "tags"),
        ]}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader>Projects</IndexHeader>
        <ul className="divide-gray-300 divide-y">
          {data.allMarkdownRemark.edges
            .filter(({ node }) => {
              const rawDate = node.frontmatter.rawDate
              const date = new Date(rawDate)
              return date < new Date()
            })
            .map(({ node }) => {
              const { title, path, tags, category, project } = node.frontmatter
              return (
                <li key={node.id} className="py-10">
                  <div className="mb-2">
                    <PillLink
                      to={`/projects/categories/${category}`}
                      variant={projectCategoryVariant(category)}
                    >
                      {category}
                    </PillLink>
                  </div>
                  <Link to={path} className="no-underline group">
                    <div className="mb-3">
                      <div className="mb-1">
                        <div className="flex items-center">
                          <h3 className="font-extrabold group-hover:text-highlight-yellow inline leading-tight text-3xl text-primary-500">
                            {title}
                          </h3>
                          <div className="ml-3">
                            <ul className="flex items-center space-x-1">
                              {project.technologies.map(({ name }) => (
                                <li>
                                  <Icon
                                    icon={name.toLowerCase()}
                                    variant="dev"
                                    className="h-6"
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-700">{node.excerpt}</p>
                  </Link>
                  <div className="mt-3 text-xs flex items-center">
                    <span className="text-gray-500 mr-3">Tags:</span>
                    <Tags tags={tags} />
                  </div>
                </li>
              )
            })}
        </ul>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { draft: { eq: false } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
            category
            tags {
              tagId
              tag
            }
            project {
              technologies {
                name
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
